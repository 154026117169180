<h1>Der Künstler</h1>
<div class="content-align-middle box">

  <div class="side-image">
    <img class="image" src="../../assets/images/donnaesotica.JPG"/>
  </div>
  <div class="content-text notranslate">
    <h2>Biografie</h2>
    <ul>
      <li>Geboren 1938 in Italien, aufgewachsen und Schulen besucht in Venedig, Florenz,
        Neapel und Carrara.
      </li>
      <li>1959 Abschluss des Maschinen-Ingenieur-Diploms in La Spezia.</li>
      <li>1959-1963 Aufenthalt in Venezuela ( Aufbau eines Stahl - und Gusseisenwerkes am Orinoco).</li>
      <li>Dort erste Fresco–Wandmalereien, die heute « Patrimonio Cultural de Venezuela» sind.</li>
      <li>Januar 1963 Einreise in die Schweiz.</li>
      <li>1963 -1969 angestellt in der Wagonfabrik Schlieren für technische Explosions - und
        Fantomzeichnungen. In dieser Zeit nebenberufliche Ausbildung an der Famous Artist
        School in Holland zum Grafiker und Illustrator.
      </li>
      <li>Ab Juni 1969 Führung des selbständigen Ateliers Vittorio Del Basso AG in Feldmeilen für
        Grafik, Illustration, Design-, Fantom- und Explosionszeichnungen, zeitweise unter
        Mitarbeit von 1-2 Angestellten. (Kunden: ABB, Sulzer, Contraves, Mövenpick, Emil Frey, Powerslide,
        Die Yacht, Automobil-Revue, Designer der Pedrazzini-Motorboote und der eigenen Yacht Stella del Mare)
      </li>
      <li>Ab 1980 auch als Erfinder tätig.</li>
      <li>1997-2002 Leiter für Produktion und Entwicklung der DBCAT AG ( multifunktionales Wasser-
        Fahrzeug).
      </li>
      <li>Seit 2002 und besonders nach der Pensionierung 2003 intensive Auseinandersetzung als
        Kunstmaler.
      </li>
    </ul>
    <div class="portrait"></div>
    <div class="content-bottom">
      <h2>Ausstellungen</h2>
      <dl>
        <dt>2003 Sept.</dt>
        <dd>Schwabach-Galerie, Feldmeilen
        <dd>
        <dt>2004 Mai</dt>
        <dd>Malbera.Galerie, Uetikon a/ See</dd>
        <dt>2005 Mai</dt>
        <dd>Galerie Vogtei, Herrliberg</dd>
        <dt>2005 Aug.</dt>
        <dd>Alte Schmiede, Üttligen BE</dd>
        <dt>2005 Okt.</dt>
        <dd>ART INTERNATIONAL, Zürich</dd>
        <dt>2006 März</dt>
        <dd>Galerie Quattro, Glattfelden</dd>
        <dt>2006 Juli</dt>
        <dd>Grand Casino, Baden</dd>
        <dt>2006 Okt.</dt>
        <dd>Centro Culturale Italiano, Zürich</dd>
        <dt>2006 Okt.</dt>
        <dd>ART INTERNATIONAL, Zürich</dd>
        <dt>2007 Juni</dt>
        <dd>Villa Liebegg, Männedorf</dd>
        <dt>2008 Febr.</dt>
        <dd>Silbando,Zürich (Tangobilder)</dd>
        <dt>2008 Juli</dt>
        <dd>Museo dei marmi, Carrara</dd>
        <dt>2009 Jan.</dt>
        <dd>Klinik Stephanshorn, St.Gallen</dd>
        <dt>2009 Nov.</dt>
        <dd>Kulturschüür Männedorf</dd>
        <dt>2011 Aug.</dt>
        <dd>Meilen kreativ</dd>
        <dt>2013 Jan.</dt>
        <dd>Physio-Center, Konstanz</dd>
        <dt>2014 Nov.</dt>
        <dd>Ortsmuseum Meilen</dd>
        <dt>2018 Nov.</dt>
        <dd>Ortsmuseum Meilen</dd>
      </dl>
    </div>
  </div>
</div>
