import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ImageService} from "../../../../admin-lib/src/lib/services/image.service";
import {GalleryImage} from "../../../../admin-lib/src/lib/models/galleryImage";
import {Observable} from "rxjs";
import {AuthenticationService} from "../../../../admin-lib/src/lib/services/authentication.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {FeatureService} from "../../../../admin-lib/src/lib/services/feature.service";
import firebase from 'firebase/compat/app';
import {UserProfile} from "../../../../admin-lib/src/lib/models/userProfile";
import {MatDialog} from "@angular/material/dialog";
import {EmailDialogComponent} from "../components/email-dialog.component";

@Component({
  selector: 'app-image-detail',
  templateUrl: './image-detail.component.html',
  styleUrls: ['./image-detail.component.scss']
})
export class ImageDetailComponent implements OnInit {
  public user: Observable<firebase.User>;

  public myForm: FormGroup;
  public control: FormControl;
  public imageData: GalleryImage[];
  public imageUrl: string;
  public category: string;
  public description: string;
  public technique: string;
  public size: string;
  public price: string;
  public updateDone = false;
  public isImgReady = false;
  public parent: string;
  public userProfile: UserProfile;
  public direction = "";
  public imgElement: HTMLElement;
  public panSpeed = 1;
  public posX = 0;
  public posY = 0;
  public scale = 1;
  public startScale = 1;
  public escale = 1;
  private slidePercent = 30;
  private id = 'id';
  private imageId: string;
  private image: HTMLElement;
  private startX = 0;
  private startY = 0;

  constructor(
    public imageService: ImageService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private fb: FormBuilder,
    private featureService: FeatureService,
    public dialog: MatDialog,
    private router: Router,
  ) {
  }

  get showInfoBox(): boolean {
    return !!this.description
      || !!this.technique
      || !!this.size
      || !!this.price;
  }

  get parentRouterLink(): string {
    return `/${this.parent}/${this.parent === 'imagelist' ? '' : this.category}`;
  }

  public ngOnInit(): void {
    if (this.imageService.sideBar) {
      this.setCssClasses();
    }
    this.user = this.authService.authUser();
    this.user.subscribe(user => {
      if (user) {
        this.featureService.getProfileFromUser(user.uid).subscribe(userProfile => {
          this.userProfile = userProfile;
        });
      }
      this.getImageData();
    });
    this.route.parent.url.subscribe(data =>
      this.parent = data[0].path);

    this.imgElement = this.elementRef.nativeElement.querySelector('.img-container');
  }

  public onPinchStart(event: any) {
    event.preventDefault();
    this.startScale = this.escale;

  }

  public onPinch(e: any) {
    if (e.type === 'pinch' && this.scale >= 1 && e.scale >= 1) {
      this.scale = e.scale * this.startScale;
      this.escale = e.scale * this.startScale;
    }
  }

  public onDoubleTap(event: any) {
    if (event.type === 'tap' && this.scale > 1) {
      this.scale = 1;
      this.posX = 0;
      this.posY = 0;
    } else if (event.type === 'tap' && this.scale === 1) {
      this.scale = event.target.naturalWidth / event.target.width;
    }
  }

  public onPanStart(event: any) {
    event.preventDefault();
    this.startX = this.posX;
    this.startY = this.posY;
  }

  public onPanMove(event: any) {
    if (this.scale <= 1
      && (this.posY <= 5 && this.posY >= -5)
      && (this.posX <= 5 && this.posX >= -5)) {
      this.posX = 0;
      this.posY = 0;
      return;
    }
    this.posX = this.startX + event.deltaX;
    this.posY = this.startY + event.deltaY;
  }

  public onPan(event: any): void {
    event.preventDefault();
    if (this.scale > 1
      || ((this.posY > 5 && this.posY < -5)
        && (this.posX > 5 && this.posX < -5))
    ) {
      return;
    }

    let percentage = 100 / this.panSpeed * event.deltaX / window.innerWidth;
    let transformPercentage = percentage - 100 / this.panSpeed * 0; // NEW
    this.imgElement.style.transform = 'scale( ' + this.scale + ' )';

    this.imgElement.style.transform += 'translateX( ' + transformPercentage + '% )';
    // @ts-ignore
    if (percentage > this.slidePercent || percentage < -this.slidePercent) {
      this.renderer.removeClass(this.elementRef.nativeElement.querySelector('#imageBox'), 'fadeIn');
      this.renderer.addClass(this.elementRef.nativeElement.querySelector('#imageBox'), 'fadeOut');
    }
    // @ts-ignore
    if (event.isFinal) { // NEW: this only runs on event end
      if (percentage < -this.slidePercent)
        this.imageService.getNextOrPreviewsImageId('Left', this.category, this.imageId).subscribe(img => {
          if (img) {
            this.router.navigate([`gallery/${img.category}/image/${img.id}`]);
          }
          this.renderer.removeClass(this.elementRef.nativeElement.querySelector('#imageBox'), 'fadeOut');
          this.renderer.addClass(this.elementRef.nativeElement.querySelector('#imageBox'), 'fadeIn');
          this.goToSlide();
        });
      else if (percentage > this.slidePercent) {
        this.imageService.getNextOrPreviewsImageId('Right', this.category, this.imageId).subscribe(img => {
          if (img) {
            this.router.navigate([`gallery/${img.category}/image/${img.id}`]);
          }
          this.renderer.removeClass(this.elementRef.nativeElement.querySelector('#imageBox'), 'fadeOut');
          this.renderer.addClass(this.elementRef.nativeElement.querySelector('#imageBox'), 'fadeIn');
          this.goToSlide();
        });
      } else {
        this.goToSlide();
      }

      this.scale = 1;
      this.posY = 0;
      this.posX = 0;
    }
  }

  public left(): void {
    this.imageService.getNextOrPreviewsImageId('Right', this.category, this.imageId).subscribe(img => {
      if (img) {
        this.router.navigate([`gallery/${img.category}/image/${img.id}`]);
      }
    });
  }

  public right(): void {
    this.imageService.getNextOrPreviewsImageId('Left', this.category, this.imageId).subscribe(img => {
      if (img) {
        this.router.navigate([`gallery/${img.category}/image/${img.id}`]);
      }
    });
  }

  public expandInfoBox(): void {
    this.imageService.sideBar = true; // only proforma, setter will be set in the service
    this.setCssClasses();
  }

  public isImgLoaded(): void {
    this.isImgReady = true;
    this.renderer.addClass(this.elementRef.nativeElement.querySelector('#imageBox'), 'fadeIn');
    this.image = this.elementRef.nativeElement.querySelector('.img-container');
  }

  public openEmailDialog(image): void {
    const dialogRef = this.dialog.open(EmailDialogComponent, {
      data: image[0]
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // send email
      }
    });
  }

  private goToSlide() {
    this.imgElement.style.transform = 'translateX( ' + 0 + '% )';
  }

  private setCssClasses() {
    if (this.imageService.sideBar) {
      this.renderer.addClass(this.elementRef.nativeElement.querySelector('#box'), 'expand');
      this.renderer.removeClass(this.elementRef.nativeElement.querySelector('#box'), 'shrink');
    } else {
      this.renderer.addClass(this.elementRef.nativeElement.querySelector('#box'), 'shrink');
      this.renderer.removeClass(this.elementRef.nativeElement.querySelector('#box'), 'expand');
    }
  }

  private getImageData() {
    this.route.paramMap.subscribe(params => {
      const imageId = this.route.snapshot.params[this.id];
      this.imageService.getImageDataById(imageId)
        .subscribe(data => {
          if (data.length === 0) {
            this.router.navigate([this.parentRouterLink]);
            return;
          }
          this.imageData = data;
          const image = this.getImage(data, imageId);
          this.imageId = image.id;
          this.imageUrl = image.url;
          this.category = image.category;
          this.description = image.description;
          this.technique = image.technique;
          this.size = image.size;
          this.price = image.price;
        });
    });
  }

  private getImage(images: GalleryImage[], id: string): GalleryImage {
    return images.find(image => image.id === id);
  }
}
