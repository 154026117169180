export const environment = {
  production: true,
  emulator: false,
  firebase: {
    apiKey: 'AIzaSyDH2KMQgdp3ropQ1Watj_02YpggwLfS7s0',
    authDomain: 'vittorio-delbasso.firebaseapp.com',
    databaseURL: 'https://vittorio-delbasso.firebaseio.com',
    projectId: 'vittorio-delbasso',
    storageBucket: 'vittorio-delbasso.appspot.com',
    messagingSenderId: '883947314242',
    appId: '1:883947314242:web:c3b66efce1336110',
    measurementId: "G-BFEPZ09WBQ"
  }
};
