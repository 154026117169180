import {Component} from '@angular/core';
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'app-vdb-root',
  templateUrl: './app.component.html',
})
export class VdbComponent {
  public title = 'Vittorio del Basso';
  public scrollDisabled = false;

  constructor(analytics: AngularFireAnalytics,
              router: Router) {
    analytics.setCurrentScreen(window.location.pathname) // sets `screen_name` parameter
    analytics.logEvent('screen_view');
    router.events.subscribe(events => {
      if (events instanceof NavigationEnd && router.url.includes('/image/')) {
        this.scrollDisabled = true;
      } else if (!router.url.includes('/image/')) {
        this.scrollDisabled = false;
      }
    });
  }
}
