<h1>Einstellungen</h1>
<div *ngIf="profiles.length > 0" class="content-align-middle">
  <mat-card class="wide-300 margin-bottom-50">

    <mat-card-content>
      <p>
        <mat-form-field appearance="fill">
          <mat-label>Select User:</mat-label>
          <mat-select (selectionChange)="getProfileFromUser($event)">
            <mat-option *ngFor="let profile of profiles"
                        [value]="profile.uid">{{profile.email}}</mat-option>
          </mat-select>
        </mat-form-field>
      </p>
      <ng-container *ngIf="profile">
        <form #loginForm="ngForm"
              (ngSubmit)="updateFeatureRights(profile.uid,loginForm.value)"
              [formGroup]="myForm">
          <p class="flex-space-between-baseline">
            Delete Image:
            <mat-button-toggle-group formControlName="deleteImage">
              <mat-button-toggle [value]="false">On</mat-button-toggle>
              <mat-button-toggle [value]="true">Off</mat-button-toggle>
            </mat-button-toggle-group>
          </p>
          <p class="flex-space-between-baseline">
            Edit Image:
            <mat-button-toggle-group formControlName="editImage">
              <mat-button-toggle [value]="true">On</mat-button-toggle>
              <mat-button-toggle [value]="false">Off</mat-button-toggle>
            </mat-button-toggle-group>
          </p>
          <p class="flex-space-between-baseline">
            Mark Image:
            <mat-button-toggle-group formControlName="markImage">
              <mat-button-toggle [value]="true">On</mat-button-toggle>
              <mat-button-toggle [value]="false">Off</mat-button-toggle>
            </mat-button-toggle-group>
          </p>
          <p class="flex-space-between-baseline">
            Make Comment:
            <mat-button-toggle-group formControlName="makeComment">
              <mat-button-toggle [value]="true">On</mat-button-toggle>
              <mat-button-toggle [value]="false">Off</mat-button-toggle>
            </mat-button-toggle-group>
          </p>
          <p class="flex-space-between-baseline">
            Show Exif:
            <mat-button-toggle-group formControlName="showExif">
              <mat-button-toggle [value]="true">On</mat-button-toggle>
              <mat-button-toggle [value]="false">Off</mat-button-toggle>
            </mat-button-toggle-group>
          </p>
          <p class="flex-space-between-baseline">
            Upload Image:
            <mat-button-toggle-group formControlName="uploadImage">
              <mat-button-toggle [value]="true">On</mat-button-toggle>
              <mat-button-toggle [value]="false">Off</mat-button-toggle>
            </mat-button-toggle-group>
          </p>
          <p class="flex-space-between-baseline">
            Admin Rights:
            <mat-button-toggle-group formControlName="adminRights">
              <mat-button-toggle [value]="true">On</mat-button-toggle>
              <mat-button-toggle [value]="false">Off</mat-button-toggle>
            </mat-button-toggle-group>
          </p>
          <p class="flex-align-items-center">
            <button mat-raised-button>Set features</button>
            <mat-icon *ngIf="updateDone" class="padding-left-10" matSuffix>check_cycle</mat-icon>
          </p>
        </form>
      </ng-container>
      <p *ngIf="msg" class="text-danger">{{msg}}</p>
    </mat-card-content>
  </mat-card>
</div>
