import {Routes} from '@angular/router';

import {StartComponent} from "./app/startpage/start.component";
import {GalleryComponent} from "./app/gallery/gallery.component";
import {AuthenticationGuard} from "../../admin-lib/src/lib/services/authenticationGuard.service";
import {UploadComponent} from "./app/upload/upload.component";
import {LoginComponent} from "./app/login/login.component";
import {ArtistComponent} from "./app/artist/artist.component";
import {UserManagementComponent} from "./app/login/user-management.component";
import {ImageDetailComponent} from "./app/image-detail/image-detail.component";
import {FeaturesComponent} from "./app/features/features.component";
import {ImageListComponent} from "./app/image-list/image-list.component";
import {GalleryOverviewComponent} from "./app/gallery-overview/gallery-overview.component";

export const appRoutes: Routes = [
  {path: 'start', component: StartComponent},
  {path: 'artist', component: ArtistComponent},
  {path: 'features', component: FeaturesComponent, canActivate: [AuthenticationGuard]},
  {path: 'gallery', component: GalleryOverviewComponent},
  {
    path: 'gallery/:cat', component: GalleryComponent, children: [
      {path: 'image/:id', component: ImageDetailComponent},
    ]
  },
  {path: 'gallery/:mark', component: GalleryComponent},
  {path: 'gallery/:cat/:mark', component: GalleryComponent},
  {path: 'upload', component: UploadComponent, canActivate: [AuthenticationGuard]},
  {path: '', redirectTo: '/start', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {
    path: 'imagelist', component: ImageListComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {path: 'image/:id', component: ImageDetailComponent},
    ]
  },
  {path: 'auth/userMgmt', component: UserManagementComponent},
];
