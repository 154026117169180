import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import {AuthenticationService} from "../../../../admin-lib/src/lib/services/authentication.service";

@Component({
  selector: 'app-footer-bar',
  templateUrl: './footer-bar.component.html',
  styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent implements OnInit, OnDestroy {

  public user: Observable<firebase.User>;
  private unsubscribe: Subject<void> = new Subject();

  constructor(private authService: AuthenticationService,
  ) {
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit() {
    this.user = this.authService.authUser();
    this.user
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        e => {

        }
      );
  }
}
