import {Injectable} from "@angular/core";

export interface ImgArrayWithSizes {
  element?: HTMLElement;
  height?: number;
  width?: number;
  marked?: boolean;
  newHeight?: number;
  newWidth?: number;
  parentWidth?: number;
  parentHeight?: number;
}

@Injectable({
  providedIn: 'root'
})

export class ImagePositioningService {
  private imgArrayWithSizes: ImgArrayWithSizes[];
  
  public loadImagesAndWaitForDecode(viewRef: HTMLElement): Promise<any[]> {
    const imgs = [];
    viewRef.childNodes.forEach((child: HTMLElement) => {
      if (child.hasChildNodes() && child.querySelector('.img-thumb')) {
        imgs.push(child);
      }
    });

    const load = child => {
      const imgTag = child.querySelector("img");
      if (!imgTag.currentSrc) {
        return;
      }
      return new Promise(res => {
        const img = new Image();
        img.src = imgTag.currentSrc;

        // further wait for the decoding
        img.onload = (evt) => {
          console.log('loaded data of a single image');
          img.decode().then(() => res(child));
        };
      });
    };

    const promises = imgs.map(async child => {
      return await load(child);
    });
    return Promise.all(promises);
  }

  public calculateImagePosition(viewRef: HTMLElement, imgHeight?, imgWidth?, padding?): ImgArrayWithSizes[] {
    const {actualContentWidth, imgArrayMulti} = this.populateMultiArray(viewRef, imgHeight, imgWidth);
    return this.adjustSizes(imgArrayMulti, actualContentWidth, imgHeight, imgWidth, padding);
  }

  public populateImageArray(viewRef: HTMLElement,
                            imgArray: any[],
                            imageCount: number,
                            imgHeight?: number,
                            imgWidth?: number,
                            padding?: number): ImgArrayWithSizes[] {
    this.imgArrayWithSizes = [];

    if (imgArray.length === imageCount) {
      // vdb
      this.imgArrayWithSizes = imgArray.map((elem: HTMLImageElement) => {
          return {
            element: elem,
            height: elem.querySelector('img').naturalHeight,
            width: elem.querySelector('img').naturalWidth,
            //marked: elem.querySelector('.bookmark-icon')
            //   && !!elem.querySelector('.bookmark-icon').classList.contains('marked'),
          }
        }
      );
      return this.calculateImagePosition(viewRef, imgHeight, imgWidth, padding);
    }
    return [];
  }

  private populateMultiArray(viewRef: HTMLElement, imgHeight?, imgWidth?): { actualContentWidth, imgArrayMulti } {
    const actualContentWidth = viewRef.clientWidth;
    const imgArrayMulti = [];
    imgArrayMulti.push([]);

    let row = 0;
    let i = 0;
    let width = 0;
    this.imgArrayWithSizes.forEach((child: ImgArrayWithSizes, index) => {
      const childWidth = imgWidth ? imgWidth : child.width;
      const childHeight = imgHeight ? imgHeight : child.height > 250 ? child.width : 250;
      const ratio = Math.ceil((childHeight / childWidth) * 100);

      if (actualContentWidth < 420) {
        imgArrayMulti.push([]);
        imgArrayMulti[row].push(child);
        row++;
      }

      if (actualContentWidth >= 420 && actualContentWidth <= 600) {
        width += ratio;
        imgArrayMulti[row].push(child);
        // use ratio, it is constant
        if (width > 200) {
          width = 0;
          imgArrayMulti.push([]);
          row++;
        }
        i++;
      }

      if (actualContentWidth >= 600 && actualContentWidth <= 800) {
        width += ratio;
        imgArrayMulti[row].push(child);
        // use ratio, it is constant
        if (width > 250) {
          width = 0;
          imgArrayMulti.push([]);
          row++;
        }
        i++;
      }

      if (actualContentWidth > 800 && actualContentWidth <= 1024) {
        width += ratio;
        imgArrayMulti[row].push(child);
        // use ratio, it is constant
        if (width > 350) {
          width = 0;
          imgArrayMulti.push([]);
          row++;
        }
        i++;
      }

      if (actualContentWidth > 1024) {
        width += ratio;
        imgArrayMulti[row].push(child);
        // use ratio, it is constant
        if (width > 500) {
          width = 0;
          imgArrayMulti.push([]);
          row++;
        }
        i++;
      }
    });
    return {actualContentWidth, imgArrayMulti};
  }

  private adjustSizes(imgArrayMulti, actualContentWidth, imgHeight?, imgWidth?, padding?): ImgArrayWithSizes[] {
    let ratio = 0;
    let newHeight = 0;
    let lastHeight = imgArrayMulti[0][0].height;
    const imgMultiArray = imgArrayMulti.map((row: ImgArrayWithSizes[]) => {
      let widthSum = 0;
      let actualHeight = 0;
      row.map(imageWithSizes => {

          if (actualHeight < imageWithSizes.height) {
            actualHeight = imgHeight ? imgHeight : imageWithSizes.height;
          }
          widthSum += imgWidth ? imgWidth : imageWithSizes.width;
        }
      );
      ratio = actualContentWidth / widthSum;

      newHeight = Math.floor(actualHeight * ratio);
      if (row.length < 2 && newHeight > 200 && (actualContentWidth >= 420 && actualContentWidth <= 800)) {
        newHeight = imgHeight ? lastHeight : 200;
      }
      if (row.length < 3 && newHeight > 200 && (actualContentWidth > 800 && actualContentWidth <= 1024)) {
        newHeight = imgHeight ? lastHeight : 200;
      }
      if (row.length < 4 && newHeight > 200 && (actualContentWidth > 1024)) {
        newHeight = imgHeight ? lastHeight : 200;
      }


      row.map(imageWithSizes => {
        const widthHeightRatio = imgHeight / (imgWidth ? imgWidth : 1);
        const originalWidthHeightRatio = imageWithSizes.height / imageWithSizes.width;
        imageWithSizes.newHeight = padding ? newHeight - padding : newHeight;
        if (imgWidth) {
          imageWithSizes.newWidth = padding ? (newHeight / widthHeightRatio) - padding
            : newHeight / widthHeightRatio;
        } else {
          imageWithSizes.newWidth = padding ? (imageWithSizes.newHeight / originalWidthHeightRatio) - padding
            : imageWithSizes.newHeight / originalWidthHeightRatio;
        }

        imageWithSizes.parentHeight = newHeight;
        imageWithSizes.parentWidth = imgWidth ? newHeight / widthHeightRatio : newHeight / originalWidthHeightRatio;
        return imageWithSizes;
      });
      lastHeight = newHeight;
      return row;
    })
    return [].concat(...imgMultiArray);
  }
}
