<h1>Einloggen</h1>
<div class="content-align-middle">
  <mat-card class="wide-300">
    <mat-card-content>
      <form #loginForm="ngForm"
            (ngSubmit)="signin(loginForm.value)"
            [formGroup]="myForm">
        <p>
          <mat-form-field class="wide-300">
            <mat-label>Email</mat-label>
            <input formControlName="email"
                   matInput
                   required>
            <mat-error>
              <span *ngIf="myFormControls.email.errors?.required">{{errorMessages.required}}</span>
              <span *ngIf="myFormControls.email.errors?.email">{{errorMessages.emailNotValid}}</span>
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="wide-300">
            <mat-label>Passwort</mat-label>
            <input formControlName="password"
                   matInput
                   required>
            <mat-error>
              <span *ngIf="myFormControls.password.errors?.required">{{errorMessages.required}}</span>
            </mat-error>
          </mat-form-field>
        </p>
        <p>
          <button class="mat-primary" mat-raised-button>Einloggen</button>
        </p>
      </form>
      <p *ngIf="msg" class="text-danger">{{msg}}</p>
      <p><a (click)="resetPassword()" onClick="javascript:void(0)">Passwort zurücksetzen</a></p>
    </mat-card-content>
  </mat-card>
</div>
