<mat-toolbar>
  <button data-title="Startseite"
          mat-button
          matTooltip="Startseite"
          matTooltipClass="tooltip"
          matTooltipPosition="left"
          routerLink="/start"
          routerLinkActive="active">
    <mat-icon matSuffix>
      home
    </mat-icon>
  </button>
  <button data-title="Der Künstler"
          mat-button
          matTooltip="Der Künstler"
          matTooltipClass="tooltip"
          matTooltipPosition="left"
          routerLink="/artist"
          routerLinkActive="active">
    <mat-icon matSuffix>
      portrait
    </mat-icon>
  </button>

  <button data-title="Kunstwerke"
          mat-button
          matTooltip="Kunstwerke"
          matTooltipClass="tooltip"
          matTooltipPosition="left"
          routerLink="/gallery"
          routerLinkActive="active">
    <mat-icon matSuffix>
      brush
    </mat-icon>
  </button>

  <button *ngIf="(user|async)?.uid && (userProfile$|async)?.adminRights"
          data-title="Einstellungen"
          mat-button
          matTooltip="Einstellungen"
          matTooltipClass="tooltip"
          matTooltipPosition="left"
          routerLink="/features"
          routerLinkActive="active">
    <mat-icon matSuffix>
      settings
    </mat-icon>
  </button>

  <button *ngIf="(user|async)?.uid && (userProfile$|async)?.uploadImage"
          data-title="Bilder hochladen"
          mat-button
          matTooltip="Bilder hochladen"
          matTooltipClass="tooltip"
          matTooltipPosition="left"
          routerLink="/upload"
          routerLinkActive="active">
    <mat-icon matSuffix>
      upload
    </mat-icon>
  </button>
  <button *ngIf="(user|async)?.uid && (userProfile$|async)?.adminRights"
          data-title="Bilderliste"
          mat-button
          matTooltip="Bilderliste"
          matTooltipClass="tooltip"
          matTooltipPosition="left"
          routerLink="/imagelist"
          routerLinkActive="active">
    <mat-icon matSuffix>
      list
    </mat-icon>
  </button>
  <button (click)="openEmailDialog('')"
          data-title="Künstler kontaktieren"
          mat-button
          matTooltip="Künstler kontaktieren"
          matTooltipClass="tooltip"
          matTooltipPosition="left">
    <mat-icon>
      email
    </mat-icon>
  </button>
  <button *ngIf="!(user|async)?.uid"
          data-title="Login"
          mat-button
          matTooltip="Einloggen"
          matTooltipClass="tooltip"
          matTooltipPosition="left"
          routerLink="/login"
          routerLinkActive="active">
    <mat-icon matSuffix>
      login
    </mat-icon>
  </button>

  <button (click)="logOut()" *ngIf="(user|async)?.uid"
          data-title="logout"
          mat-button
          matTooltip="Ausloggen"
          matTooltipClass="tooltip"
          matTooltipPosition="left"
          routerLink="/start">
    <mat-icon matSuffix>
      logout
    </mat-icon>
  </button>
</mat-toolbar>
