import {Injectable, NgModule} from '@angular/core';
import {AngularFireModule} from "@angular/fire/compat";
import {VdbComponent} from './app.component';
import {AdminLibModule} from "../../../admin-lib/src/lib/admin-lib.module";
import {environment} from "../environments/environment";
import {RouterModule, Routes} from "@angular/router";
import {appRoutes} from "../routes";
import {GalleryComponent} from "./gallery/gallery.component";
import {ImageDetailComponent} from "./image-detail/image-detail.component";
import {FooterBarComponent} from "./footer-bar/footer-bar.component";
import {StartComponent} from "./startpage/start.component";
import {NavBarComponent} from "./nav-bar/nav-bar.component";
import {UploadComponent} from "./upload/upload.component";
import {LoginComponent} from "./login/login.component";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ArtistComponent} from "./artist/artist.component";
import {UserManagementComponent} from "./login/user-management.component";
import {AngularFirestoreModule, SETTINGS} from '@angular/fire/compat/firestore';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatCardModule} from "@angular/material/card";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FeaturesComponent} from "./features/features.component";
import {MatRadioModule} from "@angular/material/radio";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatSelectModule} from "@angular/material/select";
import {ImageListComponent} from "./image-list/image-list.component";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {GalleryOverviewComponent} from "./gallery-overview/gallery-overview.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ImageDetailTableComponent} from "./detail-info/image-detail-table.component";
import {ImageDetailFormComponent} from "./detail-info/image-detail-form.component";
import {EditDialogComponent} from "./detail-info/edit-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {EmailDialogComponent} from "./components/email-dialog.component";
import {HAMMER_GESTURE_CONFIG, HAMMER_LOADER, HammerGestureConfig, HammerModule} from "@angular/platform-browser";
import {NgScrollbarModule} from "ngx-scrollbar";
import {MaterialFileInputModule} from "ngx-material-file-input";
import {NgxDropzoneModule} from "ngx-dropzone";

declare var Hammer: any;

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {


  buildHammer(element: HTMLElement) {
    const hammer = new Hammer(element, {});

    const singleTap = new Hammer.Tap({event: 'singletap'});
    const doubleTap = new Hammer.Tap({event: 'doubletap', taps: 2});
    hammer.add([doubleTap, singleTap]);

    doubleTap.requireFailure(singleTap);
    return doubleTap;

    // const mc = new Hammer.Manager(element, {
    //   touchAction: 'auto',
    //   inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput,
    //   recognizers: [
    //     [Hammer.Press, {
    //       time: 10000
    //     }]
    //   ]
    // });
    // return mc;
  };
}

const routes: Routes = [
  {path: '', component: GalleryComponent}
]

@NgModule({
  declarations: [
    VdbComponent,
    GalleryOverviewComponent,
    GalleryComponent,
    ImageDetailComponent,
    ImageDetailTableComponent,
    ImageDetailFormComponent,
    NavBarComponent,
    LoginComponent,
    UploadComponent,
    StartComponent,
    ArtistComponent,
    FeaturesComponent,
    FooterBarComponent,
    UserManagementComponent,
    ImageListComponent,
    EditDialogComponent,
    EmailDialogComponent,
  ],
  imports: [
    AdminLibModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularFirestoreModule.enablePersistence(),
    BrowserAnimationsModule,
    MatToolbarModule,
    MatMenuModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatProgressBarModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatDialogModule,
    BrowserAnimationsModule,
    HammerModule,
    NgScrollbarModule,
    MaterialFileInputModule,
    NgxDropzoneModule,
    RouterModule.forRoot(appRoutes),
  ],
  providers: [{
    provide: [HAMMER_LOADER, SETTINGS, HAMMER_GESTURE_CONFIG],
    useClass: MyHammerConfig,
    useValue: environment.emulator ? {
      host: 'localhost:5002',
      ssl: false
    } : undefined
  }],
  bootstrap: [VdbComponent],
})
export class AppModule {
}
