import {createAction, props} from "@ngrx/store";
import {GalleryImage} from "../models/galleryImage";

export const loadAllImages = createAction('[Images] Load Images');
export const loadAllImagesFinished = createAction(
  '[Images] Load Images Finished',
  props<{ payload: GalleryImage[] }>()
);

export const loadAllCategories = createAction('[Cat] Load Categories');
export const loadAllCategoriesFinished = createAction(
  '[Cat] Load Categories Finished',
  props<{ payload: GalleryImage[] }>()
);
