<mat-progress-bar *ngIf="imageLoading" color="accent" mode="indeterminate"></mat-progress-bar>
<button class="back"
        data-title="Zurück"
        mat-button
        matTooltip="Zurück"
        matTooltipClass="tooltip"
        matTooltipPosition="left"
        routerLink="/gallery"
        routerLinkActive="active">
  <mat-icon matSuffix>
    arrow_back
  </mat-icon>
</button>
<h1>{{category}}</h1>

<div *ngIf="(user|async)?.uid" class="padding-10 flex-align-items-center image-count" fxLayout="row">
  <div> {{imageCount}} Images</div>
</div>

<div *ngIf="images && images.length > 0" class="thumbnailsList" id="thumbnailsList">
  <div *ngFor="let image of images; trackBy trackByImages" class="image-box">
    <div [ngStyle]="{'background-image': 'url('+ image.urlThumb +')'}"
         [routerLink]="'image/'+ image.id"
         class="img"></div>
    <img (load)="isLoaded($event)" [src]="image.urlThumb"
         class="display-none position-absolute img-thumb"/>
  </div>
</div>
<router-outlet></router-outlet>
