<div *ngIf="percentage >= 0">
  <div class="progress">
    <div [ngStyle]="{'width': percentage + '%'}"
         class="progress-bar progress-bar-animated"></div>
  </div>
  Progress: {{percentage}}% Complete
</div>
<h1>Neue Kunstwerke hochladen</h1>
<div class="content-align-middle">
  <mat-card class="wide-800">

    <mat-card-content>
      <form #upload="ngForm"
            (ngSubmit)="uploadFiles(upload.value)"
            [formGroup]="myForm">
        <p>
          <mat-form-field class="full-width">
            <mat-label>Category</mat-label>
            <input formControlName="category"
                   matInput
                   required>
          </mat-form-field>
        </p>
        <p>
          <mat-form-field class="full-width">
            <mat-label>Description</mat-label>
            <input formControlName="description"
                   matInput>
          </mat-form-field>
        </p>
        <p>
          <ngx-dropzone (change)="onSelect($event)" [expandable]="true">
            <ngx-dropzone-label>Bilder hier hineinziehen</ngx-dropzone-label>
            <ngx-dropzone-image-preview (removed)="onRemove(f)"
                                        *ngFor="let f of imagesToUpload"

                                        [file]="f"
                                        [removable]="true"
                                        ngProjectAs="ngx-dropzone-preview">
              <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-image-preview>
          </ngx-dropzone>
        </p>
        <p>
          <button mat-raised-button>Upload</button>
        </p>
      </form>
      <p>* Please fill out!</p>
    </mat-card-content>
  </mat-card>
</div>
