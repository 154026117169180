import {Injectable} from "@angular/core";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {DocumentReference} from "@angular/fire/compat/firestore/interfaces";

export interface Email {
  description: string,
  name: string,
  email: string,
  message: string,
}

@Injectable({
  providedIn: 'root'
})

export class EmailService {
  private dbPath = 'mail';
  private emailTo = 'katharina.delbasso@hispeed.ch';

  constructor(private db: AngularFirestore) {
  }

  public sendEmailToDatabase(emailData: Email): Promise<DocumentReference> {
    return this.db.collection(this.dbPath)
      .add({
        to: this.emailTo,
        message: {
          subject: emailData.description,
          html: `${emailData.name} (${emailData.email}) hat folgendes geschrieben: ${emailData.message}`,
        },
      });
  }
}
