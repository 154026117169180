<form [formGroup]="myForm">
  <mat-form-field class="full-width">
    <mat-label>Kategorie</mat-label>
    <input formControlName="category"
           matInput
           required>
    <mat-error *ngIf="myForm.controls['category'].errors?.required">Bitte ausfüllen</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Beschreibung</mat-label>
    <textarea formControlName="description"
              matInput
              matTextareaAutosize
              maxlength="100"
    ></textarea>
    <mat-error *ngIf="myForm.controls['description'].errors">Maximale Länge erreicht</mat-error>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Technik</mat-label>
    <input formControlName="technique"
           matInput>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Grösse</mat-label>
    <input formControlName="size"
           matInput>
  </mat-form-field>

  <mat-form-field class="full-width">
    <mat-label>Preis</mat-label>
    <input formControlName="price"
           matInput>
  </mat-form-field>
  <div class="flex">
    <button (click)="updateImageDetails(myForm.value)"
            [disabled]="myForm.invalid || !myForm.dirty"
            class="mat-primary"
            mat-raised-button>Speichern
    </button>
    <mat-icon *ngIf="_updateDone === myForm.controls['id'].value" class="mat-accent icon-check" matSuffix>
      check_cycle
    </mat-icon>
    <mat-spinner *ngIf="_spin === myForm.controls['id'].value" color="accent" diameter="30"></mat-spinner>
  </div>
</form>
