export class Update {
  id: string;
  name: string;
  url: string;
  progress: number;
  createdOn: Date = new Date();
  description?: string;
  category?: string;
  technique?: string;
  size?: string;
  price?: string;

  constructor(values: Update) {
    this.description = values.description;
    this.category = values.category;
    this.technique = values.technique;
    this.size = values.size;
    this.price = values.price;
  }
}
