<h5 mat-dialog-title>Künstler kontaktieren</h5>

<ng-container *ngIf="description"></ng-container>
<form [formGroup]="myForm">
  <h5 *ngIf="data">Bild Beschreibung</h5>
  <p>{{ data ? data.description : '' }}</p>

  <p>
    <mat-form-field class="full-width">
      <mat-label>Ihr Name</mat-label>
      <input formControlName="name"
             matInput
             required>
      <mat-error *ngIf="myForm.controls['name'].errors?.required">Bitte ausfüllen</mat-error>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field class="full-width">
      <mat-label>Ihre Email</mat-label>
      <input formControlName="email"
             matInput
             required>
      <mat-error *ngIf="myForm.controls['email'].errors?.required">Bitte ausfüllen</mat-error>
      <mat-error *ngIf="myForm.controls['email'].errors?.email">Bitte korrektes Emailformat eingeben</mat-error>
    </mat-form-field>
  </p>
  <p>
    <mat-form-field class="full-width">
      <mat-label>Ihre Nachricht</mat-label>
      <textarea formControlName="message"
                matInput
                matTextareaAutosize
                maxlength="100"
                required
      ></textarea>
      <mat-error *ngIf="myForm.controls['message'].errors?.required">Bitte ausfüllen</mat-error>
    </mat-form-field>
  </p>

</form>
<div class="flex" mat-dialog-actions>
  <button mat-dialog-close mat-raised-button>Schliessen</button>
  <button (click)="sendEmail()" class="mat-primary" mat-raised-button>Email
    schicken
  </button>
  <mat-icon *ngIf="_updateDone" class="mat-accent icon-check" matSuffix>
    check_cycle
  </mat-icon>
  <mat-spinner *ngIf="_spin" color="accent" diameter="30"></mat-spinner>
</div>
