import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from "../../../../admin-lib/src/lib/services/authentication.service";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DialogComponent} from "../../../../admin-lib/src/lib/ui/dialog/dialog.component";
import {MatDialog} from "@angular/material/dialog";

const ErrorMessages: { [key: string]: string } = {
  required: 'Erforderlich.',
  emailNotValid: 'Keine gültige Email.',
};

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  myForm: FormGroup;
  password: string;
  msg: string;
  errorMessages = ErrorMessages;

  constructor(private authService: AuthenticationService,
              private router: Router,
              private fb: FormBuilder,
              public dialog: MatDialog) {
  }

  get myFormControls(): { [p: string]: AbstractControl } {
    return this.myForm['controls'];
  }

  ngOnInit(): void {
    this.myForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  public signin(myForm): void {
    if (this.myForm.dirty && this.myForm.valid) {
      this.msg = '';
      this.authService.login({email: myForm.email, password: myForm.password})
        .then(resolve => this.router.navigate(['start']))
        .catch(error => this.msg = error.message);
    }
  }

  resetPassword(): void {
    if (!this.myForm.get('email').value) {
      this.dialog.open(DialogComponent, {
        data: {
          title: 'Bitte Email eingeben.',
          buttonText: 'Ok',
        }
      });
    } else {
      this.authService.resetPasswordInit(this.myForm.get('email').value);
    }
  }
}
