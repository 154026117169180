import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {User} from '../models/user.module';
import firebase from 'firebase/compat/app';
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../ui/dialog/dialog.component";
import UserCredential = firebase.auth.UserCredential;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private user: Observable<firebase.User>;

  constructor(private afAuth: AngularFireAuth,
              public dialog: MatDialog) {
    this.user = afAuth.authState;
  }

  login(user: User): Promise<UserCredential> {
    return this.afAuth.signInWithEmailAndPassword(user.email, user.password);
  }

  logout() {
    return this.afAuth.signOut();
  }

  authUser() {
    return this.user;
  }

  getAuth() {
    return this.afAuth;
  }

  /**
   * Initiate the password reset process for this user
   * @param email email of the user
   */
  resetPasswordInit(email: string) {
    return this.afAuth.sendPasswordResetEmail(
      email,
      // {url: 'http://localhost:4200/auth'}
    )
      .then(() => {
          this.dialog.open(DialogComponent, {
            data: {
              title: 'Ein Email mit dem Zurücksetzen-Link wurde versendet.',
              buttonText: 'Ok',
            }
          });
        },
        (rejectionReason) => {
          this.dialog.open(DialogComponent, {
            data: {
              title: rejectionReason,
              buttonText: 'Ok',
            }
          });
        })
      .catch(function (error) {
        this.dialog.open(DialogComponent, {
          data: {
            title: 'Ein Fehler ist aufgetreten.',
            buttonText: 'Ok',
          }
        });
      });
    ;
  }
}
