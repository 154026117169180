import {Injectable} from '@angular/core';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {finalize} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Upload} from "../models/upload.model";
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Injectable()
export class UploadService {

  private uploadPath = '/uploads';
  private dbPath = 'photos';

  constructor(private ngFire: AngularFireModule,
              private  db: AngularFirestore,
              private storage: AngularFireStorage,
  ) {
  }

  pushFileToStorage(fileUpload: Upload): Observable<any> {
    const fileName = `${new Date().getTime()}_${fileUpload.file.name}`;
    const filePath = `${this.uploadPath}/${fileName}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    uploadTask.snapshotChanges()
      .pipe(finalize(() => {
          storageRef.getDownloadURL().subscribe(downloadURL => {
            console.log('File available at', downloadURL);
            fileUpload.url = downloadURL;
            fileUpload.name = fileName;
            this.saveFileData(fileUpload);
          });
        })
      ).subscribe();

    return uploadTask.percentageChanges();
  }

  private saveFileData(upload: Upload): void {
    const fileData = {
      url: upload.url,
      urlThumb: upload.url,
      category: upload.category,
      description: upload.description,
      name: upload.name,
      sort: upload.sort,
      technique: upload.technique,
      size: upload.size,
      price: upload.price,
    };
    this.db.collection(this.dbPath).add(fileData);
    console.log('File saved', upload.url);
  }
}
