import {NgModule} from '@angular/core';
import {AdminLibComponent} from './admin-lib.component';
import {BrowserModule} from "@angular/platform-browser";
import {AngularFireDatabaseModule} from "@angular/fire/compat/database";
import {AngularFirestore, AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {AuthenticationGuard} from "./services/authenticationGuard.service";
import {AuthenticationService} from "./services/authentication.service";
import {ImageService} from "./services/image.service";
import {UploadService} from "./services/upload.service";
import {DialogComponent} from "./ui/dialog/dialog.component";
import {DeleteComponent} from "./ui/delete/delete.component";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {HammertimeDirective} from "./directives/hammertime.directive";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {ImagesStoreModule} from "./store/images.module";


@NgModule({
  declarations: [
    AdminLibComponent,
    DeleteComponent,
    DialogComponent,
    HammertimeDirective,
  ],
  imports: [
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    BrowserModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    MatTooltipModule,
    ImagesStoreModule,
  ],
  providers: [
    AuthenticationGuard,
    AuthenticationService,
    ImageService,
    UploadService,
    AngularFireStorage,
    AngularFirestore],
  exports: [
    AdminLibComponent,
    DeleteComponent,
    HammertimeDirective,
  ]
})
export class AdminLibModule {
}
