<h1>Liste aller Kunstwerke</h1>
<div class="content-align-middle">
  <mat-card class="margin-bottom-50">
    <mat-card-content>
      <ng-container>
        <div class="table">
          <app-image-detail-table *ngIf="images?.length > 0" [imageData]="images"
                                  [isList]="true"></app-image-detail-table>
          <div *ngIf="images?.length === 0">No Images</div>
        </div>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>

