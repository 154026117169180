import {Component, OnInit} from '@angular/core';

import * as _ from 'lodash';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UploadService} from "../../../../admin-lib/src/lib/services/upload.service";
import {Upload} from "../../../../admin-lib/src/lib/models/upload.model";
import {Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {getAllImages, ImagesState} from "../../../../admin-lib/src/lib/store/images.selectors";
import {loadAllImages} from "../../../../admin-lib/src/lib/store/images.actions";


@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {

  public myForm: FormGroup;
  upload: Upload;
  public percentage: number;
  public imagesToUpload = [];
  private imageCount = 0;

  constructor(private uploadService: UploadService,
              private router: Router,
              private fb: FormBuilder,
              private store: Store<ImagesState>,
  ) {
  }

  public ngOnInit(): void {
    this.myForm = this.fb.group({
      category: ['', Validators.required],
      description: '',
      imageImport: ['', this.checkFileType],
    });
    this.store.pipe(select(getAllImages)).subscribe(images => this.imageCount = images.length);
    this.store.dispatch(loadAllImages());
  }


  public checkFileType() {
    // TODO implement custom validation
  }

  public onSelect(event) {
    this.imagesToUpload.push(...event.addedFiles);
  }

  public onRemove(event) {
    this.imagesToUpload.splice(this.imagesToUpload.indexOf(event), 1);
  }

  public uploadFiles(value): void {
    const filesToUpload = this.imagesToUpload;
    const formValues: Upload = value;

    formValues.technique = '';
    formValues.price = '';
    formValues.size = '';
    if (filesToUpload) {
      const filesIdx = _.range(filesToUpload.length);
      _.each(filesIdx, (idx) => {
        formValues.sort = this.imageCount + 1;
        const file = filesToUpload[idx];
        if (file.type.split('/'[0]).includes('image')) {
          this.upload = new Upload(file, formValues);

          this.uploadService.pushFileToStorage(this.upload).subscribe(
            percentage => {
              this.percentage = Math.round(percentage);
              const intervalId = setInterval(() => {
                if (this.percentage === 100) {
                  this.router.navigate([`gallery/${this.upload.category}`]);
                  clearInterval(intervalId);
                }
              }, 500);
            }, error => {
              console.log(error);
            });
        } else {

        }
      });
    } else {
      console.log('no filesToUpload');
    }
  }
}
