import {Component, Input, OnInit} from '@angular/core';
import {GalleryImage} from "../../../../admin-lib/src/lib/models/galleryImage";
import {Observable} from "rxjs";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {FeatureService} from "../../../../admin-lib/src/lib/services/feature.service";
import {AuthenticationService} from "../../../../admin-lib/src/lib/services/authentication.service";
import {ImageService} from "../../../../admin-lib/src/lib/services/image.service";
import {Update} from "../../../../admin-lib/src/lib/models/update.model";
import firebase from 'firebase/compat/app';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-image-detail-form',
  templateUrl: './image-detail-form.component.html',
  styleUrls: ['./image-detail-form.component.scss'],
})
export class ImageDetailFormComponent implements OnInit {
  public user: Observable<firebase.User>;

  @Input()
  public imageData: GalleryImage;

  public myForm: FormGroup;
  public category: AbstractControl;
  public description: AbstractControl;
  public technique: AbstractControl;
  public size: AbstractControl;
  public price: AbstractControl;

  constructor(
    public imageService: ImageService,
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private featureService: FeatureService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  public _updateDone = '';

  get updateDone(): string {
    return this._updateDone;
  }

  set updateDone(id) {
    this._updateDone = id;
  }

  public _spin = '';

  get spin(): string {
    return this._spin;
  }

  set spin(id) {
    this._spin = id;
  }


  public ngOnInit(): void {
    this.setForm(this.imageData);
  }

  public updateImageDetails(img: Update): void {
    if (this.myForm.dirty && this.myForm.valid) {
      this.updateDone = '';
      this.spin = img.id;
      const formValues = img;
      const update = new Update(formValues);
      this.imageService.updateImageDetailsInDatabase(img.id, update)
        .then(() => {
          this.updateDone = img.id;
          this.spin = '';
          console.log("Document successfully updated!");
        })
        .catch(error => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    }
  }

  public deleteEntry(img: GalleryImage): void {
    this.imageService.deleteFileDatabase(img.id).then(() => {
      this.imageService.deleteFileStorage(img.name);
      this.route.url.subscribe(data => {
          const url = data[0].path;
          if (url !== 'imagelist') {
            this.route.parent.url.subscribe(data => {
              this.router.navigate([`gallery/${data[1].path}`]);
            })
          }
        }
      );

    })
      .catch(error => console.log("Error Deleting From Database:", error));
  }


  private setForm(img: GalleryImage): void {
    this.myForm = this.fb.group({
      id: img.id,
      name: img.name,
      urlThumb: img.urlThumb,
      category: new FormControl({
        value: img.category,
        disabled: false,
      }, Validators.required),
      description: new FormControl({
        value: img.description || '-',
        disabled: false,
      }, Validators.maxLength(99)),
      technique: new FormControl({
        value: img.technique || '-',
        disabled: false,
      }),
      size: new FormControl({
        value: img.size || '-',
        disabled: false,
      }),
      price: new FormControl({
        value: img.price || '-',
        disabled: false,
      }),
    });

    this.myForm.valueChanges.subscribe(x => {
      this.updateDone = '';
    })
  };
}
