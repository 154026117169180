import {Component, OnDestroy, OnInit} from "@angular/core";
import {Subject} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../../../admin-lib/src/lib/services/authentication.service";
import {FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {takeUntil} from "rxjs/operators";

enum UserManagementActions {
  resetPassword = 'resetPassword',
  recoverEmail = 'recoverEmail',
  verifyEmail = 'verifyEmail'
}

const ErrorMessages: { [key: string]: string } = {
  newPasswordRequired: 'Erforderlich.',
  confirmPasswordRequired: 'Erforderlich.',
  newPassword: 'Password muss mindestends 6 Zeichen enthalten.',
  confirmPassword: 'Passwords muss übereinstimmen',
};

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./login.component.scss']
})
export class UserManagementComponent implements OnInit, OnDestroy {

  ngUnsubscribe: Subject<any> = new Subject<any>();
  actions = UserManagementActions;

  myForm: FormGroup;
  msg: string;

  errorMessages = ErrorMessages;

  // The user management action to be completed
  mode: string;

  // Just a code Firebase uses to prove that
  // this is a real password reset.
  actionCode: string;
  actionCodeChecked: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService,
    private fb: FormBuilder
  ) {
  }

  get newPassword() {
    return this.myForm?.get('newPassword');
  }

  get confirmPassword() {
    return this.myForm?.get('confirmPassword');
  }

  ngOnInit() {
    this.myForm = this.fb.group({
        newPassword: ['', [
          Validators.required,
          Validators.minLength(6)]],
        confirmPassword: ['',
          Validators.required
        ],
      },
      {
        validators: [this.notMatchValidator()]
      });
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(params => {
        // if we didn't receive any parameters,
        // we can't do anything
        if (!params) this.router.navigate(['/start']);

        this.mode = params['mode'];
        this.actionCode = params['oobCode'];

        switch (params['mode']) {
          case UserManagementActions.resetPassword: {
            // Verify the password reset code is valid.
            this.authService
              .getAuth()
              .verifyPasswordResetCode(this.actionCode)
              .then(email => {
                this.actionCodeChecked = true;
              }).catch(e => {
              // Invalid or expired action code. Ask user to try to
              // reset the password again.
              alert(e);
              this.router.navigate(['/login']);
            });
          }
            break
          case UserManagementActions.recoverEmail: {

          }
            break
          case UserManagementActions.verifyEmail: {

          }
            break
          default: {
            console.log('query parameters are missing');
            this.router.navigate(['/login']);
          }
        }
      })
  }

  ngOnDestroy() {
    // End all subscriptions listening to ngUnsubscribe
    // to avoid memory leaks.
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Attempt to confirm the password reset with firebase and
   * navigate user back to home.
   */
  handleResetPassword(myForm) {
    this.myForm.get('confirmPassword').updateValueAndValidity();
    if (this.myForm.valid) {
      // Save the new password.
      this.authService.getAuth().confirmPasswordReset(
        this.actionCode,
        myForm.newPassword
      )
        .then(resp => {
          // Password reset has been confirmed and new password updated.
          alert('Das neue Passwort wurde gespeichert.');
          this.router.navigate(['/login']);
        }).catch(e => {
        // Error occurred during confirmation. The code might have
        // expired or the password is too weak.
        alert(e);
      });
    }
  }

  private notMatchValidator(): ValidatorFn {
    return (fg: FormGroup): { [key: string]: any } | null => {
      const forbidden = this.newPassword?.value != this.confirmPassword?.value;
      return forbidden ? {matchName: {value: fg.value}} : null;
    };
  }
}
