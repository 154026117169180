import {Component, ElementRef, HostListener, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {GalleryImage} from '../../../../admin-lib/src/lib/models/galleryImage';
import {AuthenticationService} from '../../../../admin-lib/src/lib/services/authentication.service';
import {ImageService} from '../../../../admin-lib/src/lib/services/image.service';
import {UserProfile} from "../../../../admin-lib/src/lib/models/userProfile";
import {FeatureService} from "../../../../admin-lib/src/lib/services/feature.service";
import {
  ImagePositioningService,
  ImgArrayWithSizes
} from "../../../../admin-lib/src/lib/services/image-positioning.service";
import {select, Store} from "@ngrx/store";
import {getAllImages, ImagesState} from "../../../../admin-lib/src/lib/store/images.selectors";
import {loadAllImages} from "../../../../admin-lib/src/lib/store/images.actions";

export class ExifData {
  apertureValue: number;
  exposureTime: number;
  fNumber: number;
  focalLength: number;
  focalLengthIn35mmFormat: number;
  ISO: number;
  lensMake: string;
  lensModel: string;
  pixelXDimension: number;
  pixelYDimension: number;
  shutterSpeedValue: number;
  whiteBalance: number;
}

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush, // add this
})

export class GalleryComponent implements OnInit, OnDestroy {
  public user: Observable<firebase.User>;
  public viewRef: HTMLElement;
  public category = '';
  public marked = false;
  public imageCount = 0;
  public images: GalleryImage[];
  public imageLoading = false;
  public userProfile$: Observable<UserProfile>;
  private unsubscribe: Subject<void> = new Subject();
  private cat = 'cat';
  private mark = 'mark';
  private imageCounter = 0;
  private padding = 15;

  constructor(private authService: AuthenticationService,
              private imageService: ImageService,
              private storage: AngularFireStorage,
              private db: AngularFirestore,
              private route: ActivatedRoute,
              private router: Router,
              private renderer: Renderer2,
              private elementRef: ElementRef,
              private featureService: FeatureService,
              private imagePositioningService: ImagePositioningService,
              private store: Store<ImagesState>,
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.renderImagesHeight(this.imagePositioningService.calculateImagePosition(this.viewRef, undefined, undefined, this.padding));
  }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public ngOnInit() {
    this._initContent();
  }

  // fire if browser object is loaded
  public isLoaded(e: Event): void {
    this.imageCounter++;
    // after all objects are loaded
    if (this.imageCount === this.imageCounter) {
      this.imageLoading = false;
      this.viewRef = this.elementRef.nativeElement.querySelector('#thumbnailsList');
      this.imagePositioningService.loadImagesAndWaitForDecode(this.viewRef).then(imageArray => {
        console.log("all done", imageArray);
        this.renderImagesHeight(this.imagePositioningService
          .populateImageArray(this.viewRef, imageArray, this.imageCount, undefined, undefined, this.padding));
      });

      this.imageCounter = 0;
      this.renderer.addClass(this.viewRef, 'fadeIn');
    }
  }

  public markImage(event: Event, galleryImage) {
    event.stopPropagation();
    this.imageService.markImage(event, galleryImage);
  }

  public unMarkFilter() {
    if (this.marked) {
      this.router.navigateByUrl(`gallery/${this.category}`);
      this.marked = false;
    }
  }

  public showExifData(event: Event) {
    this.renderer.addClass(event.currentTarget, 'open-exif');
  }

  public getExif(exif): ExifData {
    if (!exif.exif) {
      return;
    }
    return {
      apertureValue: exif.exif.ApertureValue,
      exposureTime: exif.exif.ExposureTime,
      fNumber: exif.exif.FNumber,
      focalLength: exif.exif.FocalLength,
      focalLengthIn35mmFormat: exif.exif.FocalLengthIn35mmFormat,
      ISO: exif.exif.ISO,
      lensMake: exif.exif.LensMake,
      lensModel: exif.exif.LensModel,
      pixelXDimension: exif.exif.PixelXDimension,
      pixelYDimension: exif.exif.PixelYDimension,
      shutterSpeedValue: exif.exif.ShutterSpeedValue,
      whiteBalance: exif.exif.WhiteBalance,
    }
  }

  public trackByImages(index, item: GalleryImage) {
    return item.id;
  }

  private renderImagesHeight(imageArrayWithSizes: ImgArrayWithSizes[]): void {
    if (imageArrayWithSizes.length > 0) {
      imageArrayWithSizes.map(imageWithSizes => {
        this.renderer.setStyle(imageWithSizes.element
          .querySelector('.img'), 'height', imageWithSizes.newHeight + 'px');
        this.renderer.setStyle(imageWithSizes.element
          .querySelector('.img'), 'width', imageWithSizes.newWidth + 'px');
        this.renderer.setStyle(imageWithSizes.element, 'height', imageWithSizes.parentHeight + 'px');
        if (imageWithSizes.parentWidth) {
          // this.renderer.setStyle(imageWithSizes.element.querySelector('img'), 'width', imageWithSizes.newWidth + 'px');
          this.renderer.setStyle(imageWithSizes.element, 'width', imageWithSizes.parentWidth + 'px');
        }
      });
    }
  }

  private _initContent(): void {
    this.user = this.authService.authUser();
    this.user.subscribe(user => {
      if (user) {
        this.userProfile$ = this.featureService.getProfileFromUser(user.uid);
      }
    });
    this.imageLoading = true;

    // get images form store
    this.store.pipe(select(getAllImages)).subscribe(images => {
      this.initRouting(images);
    });

    this.store.dispatch(loadAllImages());
  }

  private initRouting(photos): void {
    this.route.paramMap.subscribe(params => {


      let category = this.route.snapshot.params[this.cat];

      if (this.viewRef && this.category !== category) {
        this.renderer.removeClass(this.viewRef, 'fadeIn');
        this.imageCount = 0;
        this.imageLoading = true;
        this.images = [];
      }
      let marked = !!this.route.snapshot.params[this.mark];
      let p: GalleryImage[];
      this.category = category;
      marked = marked || category === 'marked' ? true : false;
      if (category) {
        p = photos.filter(photo => category != 'marked' ? photo.category === category : true);
        if (marked) {
          p = p.filter(photo => photo.mark === true);
          this.marked = true;
        }
      } else {
        p = photos;
      }
      if (p.length === 0) {
        // this.router.navigate(['/gallery']);
      }
      if (this.imageCount === 0 || this.imageCount === p.length) {
        this.images = p.sort((a, b) => a.sort - b.sort);
      }
      this.imageCount = p.length;
    });
  }
}
