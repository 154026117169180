<mat-progress-bar *ngIf="galleryLoading" color="accent" mode="indeterminate"></mat-progress-bar>
<h1>Kunstwerke</h1>
<div *ngIf="categoriesWithUrlFromFirstImg?.length > 0" class="galleryOverviewList">
  <div *ngFor="let cat of categoriesWithUrlFromFirstImg" class="img-box">
    <div class="cat-name">{{cat.category}}</div>
    <div [ngStyle]="{'background-image': 'url('+ cat.url +')'}"
         [routerLink]="cat.category" class="cat-img"></div>
    <img (load)="isLoaded(cat.category)" [src]="cat.url"
         class="display-none position-absolute img-thumb"/>
  </div>
</div>
<div *ngIf="!galleryLoading && categoriesWithUrlFromFirstImg?.length === 0" class="content-align-middle">Keine Inhalte
</div>
<router-outlet></router-outlet>
