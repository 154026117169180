import {ActionReducerMap, createFeatureSelector, createSelector} from '@ngrx/store';
import {imagesReducer, ReducerImagesState} from './images.reducer';

export const featureStateName = 'imagesFeature';

export interface ImagesState {
  images: ReducerImagesState;
}

export const imagesReducers: ActionReducerMap<ImagesState> = {
  images: imagesReducer,
};

// extract the main property 'imagesFeature' from the state object
export const getImagesFeatureState = createFeatureSelector<ImagesState>(
  featureStateName
);

export const getAllImages = createSelector(
  getImagesFeatureState,
  (state: ImagesState) => state.images.images,
);

export const getAllCategories = createSelector(
  getImagesFeatureState,
  (state: ImagesState) => {
    const cats = [];
    state.images.images.map(photo => {
      if (photo.category === '') {
        console.log("mapimages category ", photo);
        return '';
      }
      const cat = {category: photo.category, url: photo.urlThumb};
      cats.push(cat);
    });
    return cats.reduce((arr, item) => {
      let exists = !!arr.find(x => x.category === item.category);
      if (!exists) {
        arr.push(item);
      }
      return arr;
    }, []);
  }
);
