import {GalleryImage} from "../models/galleryImage";
import {Action, createReducer, on} from "@ngrx/store";
import {loadAllCategories, loadAllCategoriesFinished, loadAllImages, loadAllImagesFinished} from "./images.actions";

export interface ReducerImagesState {
  images: GalleryImage[];
  loading: boolean;
}

export const initialState: ReducerImagesState = {
  images: [],
  loading: false,
};

const imagesReducerInternal = createReducer(
  initialState,
  on(
    loadAllImages,
    loadAllCategories,
    state => ({
      ...state,
      loading: true
    })
  ),
  on(loadAllImagesFinished, (state, {payload}) => ({
    ...state,
    loading: false,
    images: [...payload]
  })),
  on(loadAllCategoriesFinished, (state, {payload}) => ({
    ...state,
    loading: false,
    images: [...payload]
  })),
);

export function imagesReducer(
  state: ReducerImagesState | undefined,
  action: Action
) {
  return imagesReducerInternal(state, action);
}
