import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {User} from '../models/user.module';
import {UserProfile} from '../models/userProfile';
import {map} from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import QuerySnapshot = firebase.firestore.QuerySnapshot;

@Injectable({
  providedIn: 'root'
})
export class FeatureService {

  private user: Observable<User>;
  private rights: UserProfile[];

  constructor(private db: AngularFirestore) {
  }

  public getAllProfiles(): Observable<QuerySnapshot> {
    return this.db.collection('userProfiles')
      .get();
  }

  public getProfileFromUser(userId: string): Observable<UserProfile> {
    return this.db.collection('userProfiles')
      .doc<UserProfile>(userId)
      .valueChanges()
      .pipe(map(profile => profile));
  }

  public updateFeatureRight(userId: string, userProfile: UserProfile): Promise<void> {
    return this.db.collection('userProfiles').doc(userId).update(userProfile);
  }
}
