import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthenticationService} from "../../../../admin-lib/src/lib/services/authentication.service";
import {Router} from "@angular/router";
import {FeatureService} from "../../../../admin-lib/src/lib/services/feature.service";
import {User} from "../../../../admin-lib/src/lib/models/user.module";
import {UserProfile} from "../../../../admin-lib/src/lib/models/userProfile";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})

export class FeaturesComponent implements OnInit{
  myForm: FormGroup;
  deleteImage: boolean;
  editImage: boolean;
  makeComment: boolean;
  markImage: boolean;
  showExif: boolean;
  uploadImage: boolean;
  adminRights: boolean;
  updateDone = false;
  msg: string;
  users: Promise<void | User[]>;
  profiles: UserProfile[] = [];
  profile: UserProfile;

  constructor(private authService: AuthenticationService,
              private featureService: FeatureService,
              private router: Router,
              private fb: FormBuilder) {
  }

  public ngOnInit(): void {
    this.getUserProfile();
  }

  public getProfileFromUser(user: MatSelectChange) {
    this.profile = this.profiles.find(profile => profile.uid === user.value);
    this.buildFormGroup(this.profile);
    this.myForm.valueChanges.subscribe(x => {
      this.updateDone = false;
    })
  }

  public getUserProfile() {
    this.featureService.getAllProfiles().subscribe(snapshot => {
      snapshot.forEach(doc => {
        this.profiles.push(
          {
            adminRights: doc.data().adminRights,
            deleteImage: doc.data().deleteImage,
            editImage: doc.data().editImage,
            email: doc.data().email,
            makeComment: doc.data().makeComment,
            showExif: doc.data().showExif,
            markImage: doc.data().markImage,
            uploadImage: doc.data().uploadImage,
            uid: doc.id,
          });
      });
    });
  }

  updateFeatureRights(uid, thisForm) {
    this.featureService.updateFeatureRight(uid, {
      deleteImage: thisForm.deleteImage,
      editImage: thisForm.editImage,
      makeComment: thisForm.makeComment,
      markImage: thisForm.markImage,
      showExif: thisForm.showExif,
      uploadImage: thisForm.uploadImage,
      adminRights: thisForm.adminRights,
    })
      .then(resolve => {
        this.updateDone = true;
      })
      .catch(error => this.msg = error.message);
    ;
  }

  private buildFormGroup(profile: UserProfile) {
    this.myForm = this.fb.group({
      user: [User],
      deleteImage: profile.deleteImage,
      editImage: profile.editImage,
      makeComment: profile.makeComment,
      markImage: profile.markImage,
      showExif: profile.showExif,
      uploadImage: profile.uploadImage,
      adminRights: profile.adminRights,
    });
  }
}
