import {NgModule} from "@angular/core";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {ImagesEffects} from "./images.effects";
import {featureStateName, imagesReducers} from "./images.selectors";

@NgModule({
  imports: [
    StoreModule.forFeature(featureStateName, imagesReducers),
    EffectsModule.forFeature([ImagesEffects]),
  ],
  declarations: [
    // ...
  ],
})
export class ImagesStoreModule {
}
