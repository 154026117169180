import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {loadAllCategories, loadAllCategoriesFinished, loadAllImages, loadAllImagesFinished} from "./images.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {ImageService} from "../services/image.service";
import {of} from "rxjs";

@Injectable()
export class ImagesEffects {
  loadImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllImages),
      switchMap(action =>
        this.imageService.getImagesData().pipe(
          map(images => loadAllImagesFinished({payload: images})),
          catchError(error => of(error))
        )
      )
    )
  );

  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAllCategories),
      switchMap(action =>
        this.imageService.getImagesData().pipe(
          map(images => loadAllCategoriesFinished({payload: images})),
          catchError(error => of(error))
        )
      )
    )
  );

  constructor(private actions$: Actions, private imageService: ImageService) {
  }
}
