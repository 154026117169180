import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticationService} from "../../../../admin-lib/src/lib/services/authentication.service";
import {FeatureService} from "../../../../admin-lib/src/lib/services/feature.service";
import {UserProfile} from "../../../../admin-lib/src/lib/models/userProfile";
import firebase from 'firebase/compat/app';
import {EmailDialogComponent} from "../components/email-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  public navTitle = '<span>Vittorio del Basso</span>';
  public user: Observable<firebase.User>;
  public userProfile$: Observable<UserProfile>;
  public categories: string[];
  public isCollapsed = true;

  constructor(private authService: AuthenticationService,
              private featureService: FeatureService,
              private router: Router,
              public dialog: MatDialog,
  ) {
  }

  public toggleNav(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public ngOnInit(): void {
    this.checkFeatureRights();
  }

  public openEmailDialog(image): void {
    if (image) {
      image.subscribe(data => {
        const dialogRef = this.dialog.open(EmailDialogComponent, {
          data: data[0]
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === true) {
            // send email
          }
        });
      })
    } else {
      const dialogRef = this.dialog.open(EmailDialogComponent, {
        data: undefined
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          // send email
        }
      });
    }

  }

  public logOut(): void {
    this.authService.logout()
      .then(onResolve => this.router.navigate(['start']))
      .catch(error => console.log(error.message));
  }

  private checkFeatureRights(): void {
    this.user = this.authService.authUser();
    this.user.subscribe(user => {
      if (user) {
        this.userProfile$ = this.featureService.getProfileFromUser(user.uid);
      }
    });
  }
}
