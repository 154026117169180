import {Component, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';
import {AngularFireStorage} from '@angular/fire/compat/storage';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AuthenticationService} from '../../../../admin-lib/src/lib/services/authentication.service';
import {Category} from '../../../../admin-lib/src/lib/services/image.service';
import {
  ImagePositioningService,
  ImgArrayWithSizes
} from "../../../../admin-lib/src/lib/services/image-positioning.service";
import {select, Store} from "@ngrx/store";
import {getAllCategories, ImagesState} from "../../../../admin-lib/src/lib/store/images.selectors";
import {loadAllCategories} from "../../../../admin-lib/src/lib/store/images.actions";

@Component({
  selector: 'app-gallery-overview',
  templateUrl: './gallery-overview.component.html',
  styleUrls: ['./gallery-overview.component.scss'],
})

export class GalleryOverviewComponent implements OnInit {
  public viewRef: HTMLElement;
  public category = '';
  public catCount = 0;
  public galleryLoading = false;
  public categoriesWithUrlFromFirstImg: Category[] = [];
  public galleryCounter = 0;
  private imgHeight = 400;
  private imgWidth = 400;
  private padding = 30;

  constructor(private authService: AuthenticationService,
              private storage: AngularFireStorage,
              private db: AngularFirestore,
              private route: ActivatedRoute,
              private router: Router,
              private renderer: Renderer2,
              private elementRef: ElementRef,
              private imagePositioningService: ImagePositioningService,
              private store: Store<ImagesState>,
  ) {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.renderImagesHeight(this.imagePositioningService.calculateImagePosition(this.viewRef, this.imgHeight, this.imgWidth, this.padding));
  }

  public ngOnInit() {
    let count = 0;
    this.store.pipe(select(getAllCategories)).subscribe(cats => {
        console.log("load", count++, cats);
        if (this.viewRef) {
          this.renderer.removeClass(this.viewRef, 'fadeIn');
        }
        this.galleryLoading = true;
        this.catCount = cats.length;
        this.categoriesWithUrlFromFirstImg = cats;
      }
    );
    this.store.dispatch(loadAllCategories());
  }

  // fire if browser object is loaded
  public isLoaded(cat: string): void {
    this.galleryCounter++;
    // after all objects are loaded
    if (this.catCount === this.galleryCounter) {
      this.galleryLoading = false;
      this.viewRef = this.elementRef.nativeElement.querySelector('.galleryOverviewList');
      this.imagePositioningService.loadImagesAndWaitForDecode(this.viewRef).then(imageArray => {
        console.log("all done", imageArray);
        this.renderImagesHeight(this.imagePositioningService
          .populateImageArray(this.viewRef, imageArray, this.catCount, this.imgHeight, this.imgWidth, this.padding));
      });

      this.galleryCounter = 0;
      this.renderer.addClass(this.viewRef, 'fadeIn');
    }
  }

  private renderImagesHeight(imageArrayWithSizes: ImgArrayWithSizes[]): void {
    console.log("imageArrayWithSizes", imageArrayWithSizes);
    if (imageArrayWithSizes.length > 0) {
      imageArrayWithSizes.map(imageWithSizes => {
        this.renderer.setStyle(imageWithSizes.element
          .querySelector('.cat-img'), 'height', imageWithSizes.newHeight + 'px');
        this.renderer.setStyle(imageWithSizes.element
          .querySelector('.cat-img'), 'width', imageWithSizes.newWidth + 'px');
        this.renderer.setStyle(imageWithSizes.element, 'height', imageWithSizes.parentHeight + 'px');
        if (imageWithSizes.parentWidth) {
          this.renderer.setStyle(imageWithSizes.element, 'width', imageWithSizes.parentWidth + 'px');
        }
      });
    }
  }
}
