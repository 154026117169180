<div class="box" id="box">
  <button [routerLink]="parentRouterLink"
          class="close"
          data-title="Schliessen"
          mat-button
          matTooltip="Schliessen"
          matTooltipClass="tooltip"
          matTooltipPosition="left">
    <mat-icon>
      close
    </mat-icon>
  </button>
  <button (click)="openEmailDialog(imageData)"
          class="email-top"
          data-title="Künstler kontaktieren"
          mat-button
          matTooltip="Künstler kontaktieren"
          matTooltipClass="tooltip"
          matTooltipPosition="left">
    <mat-icon>
      email
    </mat-icon>

  </button>

  <button (click)="left()"
          class="left"
          data-title="Vorheriges"
          mat-button
          matTooltip="Vorheriges"
          matTooltipClass="tooltip"
          matTooltipPosition="right">
    <mat-icon>
      arrow_back
    </mat-icon>
  </button>
  <button (click)="right()"
          class="right"
          data-title="Nächstes"
          mat-button
          matTooltip="Nächstes"
          matTooltipClass="tooltip"
          matTooltipPosition="left">
    <mat-icon>
      arrow_forward
    </mat-icon>
  </button>

  <div
    (doubletap)="onDoubleTap($event)"
    (pan)="onPan($event)"
    (panmove)="onPanMove($event)"
    (panstart)="onPanStart($event)"
    (pinch)="onPinch($event)"
    (pinchstart)="onPinchStart($event)"
    class="image-box"
    hammertime
    id="imageBox">

    <img (load)="isImgLoaded()"
         [src]="imageUrl"
         [style.left.px]="posX"
         [style.top.px]="posY"
         [style.transform]="'scale('+scale+')'"
         class="img-container"/>
  </div>
  <div *ngIf="(user | async)?.uid || showInfoBox" class="info-box" id="infoBox">
    <button (click)="expandInfoBox()"
            class="info"
            data-title="Information"
            mat-button
            matTooltip="Information"
            matTooltipClass="tooltip"
            matTooltipPosition="left">
      <mat-icon matSuffix>
        read_more
      </mat-icon>
    </button>
    <div class="info-table">
      <app-image-detail-table [imageData]="imageData"></app-image-detail-table>
    </div>
  </div>
</div>

