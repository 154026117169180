import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface DialogData {
  title?: string;
  text?: string;
  buttonCancel?: string;
  buttonText?: string;
}

@Component({
  selector: 'dialog-box',
  templateUrl: 'dialog.component.html',
})

export class DialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }
}
