import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {GalleryImage} from "../../../../admin-lib/src/lib/models/galleryImage";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Email, EmailService} from "../../../../admin-lib/src/lib/services/email.service";

@Component({
  selector: 'app-email-dialog-box',
  templateUrl: 'email-dialog.component.html',
  styleUrls: ['email-dialog.component.scss'],
})

export class EmailDialogComponent {
  public myForm: FormGroup;
  public description: AbstractControl;

  constructor(@Inject(MAT_DIALOG_DATA) public data: GalleryImage | undefined,
              private fb: FormBuilder,
              private emailService: EmailService,
              public dialogRef: MatDialogRef<EmailDialogComponent>,
  ) {
    this.setForm();
  }

  public _updateDone = false;

  get updateDone(): boolean {
    return this._updateDone;
  }

  set updateDone(id) {
    this._updateDone = id;
  }

  public _spin = false;

  get spin(): boolean {
    return this._spin;
  }

  set spin(id) {
    this._spin = id;
  }

  public sendEmail(): void {
    if (!this.myForm.controls['email'].errors) {
      this.updateDone = false;
      this.spin = true;

      const emailData: Email = {
        description: this.data.description,
        name: this.myForm.controls['name'].value,
        email: this.myForm.controls['email'].value,
        message: this.myForm.controls['message'].value,

      }
      this.emailService.sendEmailToDatabase(emailData)
        .then(() => {
          this.updateDone = true;
          this.spin = false;
          this.dialogRef.close();
          console.log("Email was successfully sent!");
        })
        .catch(error => {
          this.spin = false;
          // The document probably doesn't exist.
          console.error("Error sending email: ", error);
        });
    }
  }

  private setForm(): void {
    this.myForm = this.fb.group({
      name: new FormControl({
        value: '',
        disabled: false,
      }, Validators.required),
      message: new FormControl({
        value: '',
        disabled: false,
      }, Validators.required),
      email: new FormControl({
        value: '',
        disabled: false,
      }, [Validators.required, Validators.email]),
    });
  };
}
