<h1>Password zurücksetzen</h1>
<div class="content-align-middle">
  <mat-card>
    <mat-card-content>

      <ng-container *ngIf="actionCodeChecked" [ngSwitch]="mode">
        <!-- Reset Password -->
        <ng-container *ngSwitchCase="'resetPassword'">
          <form #loginForm="ngForm"
                (ngSubmit)="handleResetPassword(loginForm.value)"
                [formGroup]="myForm">
            <div class="padding-bottom-10">
              <mat-form-field class="wide-300">
                <mat-label for="newPassword">Neues Password</mat-label>
                <input formControlName="newPassword"
                       id="newPassword"
                       matInput
                       required>
                <mat-error>
                  <span
                    *ngIf="myForm.get('newPassword').hasError('required')">{{errorMessages.newPasswordRequired}}
                  </span>
                  <span *ngIf="myForm.get('newPassword').hasError('minlength')">{{errorMessages.newPassword}}</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="padding-bottom-10">
              <mat-form-field class="wide-300">
                <mat-label for="confirmPassword">Password Bestätigen</mat-label>
                <input formControlName="confirmPassword"
                       id="confirmPassword"
                       matInput
                       required>
                <mat-error>
                  <span
                    *ngIf="myForm.get('confirmPassword').hasError('required')">{{errorMessages.confirmPasswordRequired}}
                  </span>
                  <span
                    *ngIf="myForm.get('confirmPassword').hasError('matchName')">{{errorMessages.confirmPassword}}
                  </span>
                </mat-error>
              </mat-form-field>

            </div>
            <mat-error>
              <span
                *ngIf="myForm.get('newPassword').valid && myForm.get('confirmPassword').valid && myForm.errors?.matchName">{{errorMessages.confirmPassword}}
              </span>
            </mat-error>
            <p>* Bitte ausfüllen!</p>
            <p>
              <button [disabled]="!myForm.valid" mat-raised-button>Password zurücksetzen</button>
            </p>
          </form>
        </ng-container>
        <!-- Recover Email -->
        <ng-container *ngSwitchCase="'recoverEmail'">
        </ng-container>

        <!-- Verify Email -->
        <ng-container *ngSwitchCase="'verifyEmail'">
        </ng-container>
      </ng-container>

    </mat-card-content>
  </mat-card>
</div>
