import {Injectable} from '@angular/core';
import 'firebase/storage';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {GalleryImage} from "../models/galleryImage";
import firebase from 'firebase/compat/app';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Update} from "../models/update.model";

type CollectionReference = firebase.firestore.CollectionReference;

export interface Category {
  category: string,
  url: string,
}

@Injectable({
  providedIn: 'root'
})

export class ImageService {

  public images$: Observable<GalleryImage[]>;
  private sideBarExpanded = false;
  private uid: string;
  private basePathThumb = '/small';
  private basePath = '/big';
  private dbPath = 'photos';

  constructor(private afAuth: AngularFireAuth,
              private db: AngularFirestore) {
    this.afAuth.authState.subscribe(auth => {
      if (auth !== undefined && auth !== null) {
        this.uid = auth.uid;
      }
    });
    this.images$ = this.loadImagesData();
  }

  public get sideBar(): boolean {
    return this.sideBarExpanded;
  }

  // preserve sideBar position
  public set sideBar(notUsed: boolean) {
    this.sideBarExpanded = !this.sideBarExpanded;
  }

  public getImagesData(): Observable<GalleryImage[]> {
    return this.images$;
  }

  public getImageDataById(id: string): Observable<GalleryImage[]> {
    return this.images$
      .pipe(map(imgs => {
        return imgs.filter(img => img.id === id);
      }));
  }

  public getNextOrPreviewsImageId(direction: string, category: string, id: string): Observable<GalleryImage> {
    return this.images$
      .pipe(
        map(imgs => {
          return imgs.filter(img => img.category === category);
        })
      )
      .pipe(
        map(imgsByCat => {
          const sortedImgByCat = imgsByCat.sort((a, b) => a.sort - b.sort);
          const currentImageIndex = sortedImgByCat.findIndex(img => img.id === id);
          if ((currentImageIndex === sortedImgByCat.length && direction === 'Left')
            || (currentImageIndex === 0 && direction === 'Right')) {
            return;
          }
          return sortedImgByCat[direction === 'Left' ? currentImageIndex + 1 : currentImageIndex - 1];
        })
      );
  }

  public updateImageDetailsInDatabase(id, fileUpdate: Update): Promise<void> {
    return this.db.collection(this.dbPath)
      // set merge true if field is missing
      .doc(id).update({
        category: fileUpdate.category,
        description: fileUpdate.description,
        technique: fileUpdate.technique,
        size: fileUpdate.size,
        price: fileUpdate.price,
      });
  }

  public deleteFileDatabase(id: string): Promise<void> {
    return this.db.collection('photos').doc(id).delete();
  }

  public deleteFileStorage(name: string): void {
    const storageRef = firebase.storage().ref();
    this.deleteBig(storageRef, name).then(() => {
      this.deleteSmall(storageRef, name).then(() => {
        console.log("File deleted")
      })
        .catch(error => console.log("Error Deleting small image:", error));
    })
      .catch(error => console.log("Error Deleting big image:", error));
  }

  public markImage(event: Event, galleryImage): void {
    this.db.collection('photos').doc(galleryImage.id).update({mark: galleryImage.mark ? false : true});
  }

  private loadImagesData(): Observable<GalleryImage[]> {
    return this.db.collection<GalleryImage>(this.dbPath, ref => {
      let retVal = ref as CollectionReference;
      retVal.get().then(querySnapshot => {
        querySnapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            //console.log('New image: ', change.doc.data());
          }
          if (change.type === 'modified') {
            console.log('Modified image: ', change.doc.data());
          }
          if (change.type === 'removed') {
            console.log('Removed image: ', change.doc.data());
          }
          const source = change.doc.metadata.fromCache ? "local cache" : "server";
          console.log("Data came from " + source);
        })
      });
      return retVal;
    })
      .valueChanges({idField: 'id'})
      .pipe(
        shareReplay(1)
      );
  }

  private deleteBig(storageRef: firebase.storage.Reference, name: string): Promise<void> {
    return storageRef.child(`${this.basePath}/big_${name}`).delete();
  }

  private deleteSmall(storageRef: firebase.storage.Reference, name: string): Promise<void> {
    return storageRef.child(`${this.basePathThumb}/small_${name}`).delete();
  }
}
