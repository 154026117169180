import {Component, EventEmitter, Output} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {DialogComponent} from "../dialog/dialog.component";

@Component({
  selector: 'delete',
  templateUrl: 'delete.component.html',
})
export class DeleteComponent {

  @Output()
  public deleteEvent = new EventEmitter();

  constructor(public dialog: MatDialog) {
  }

  openDialog() {
    const dialogRef = this.dialog.open(DialogComponent, {
      data: {title: 'Willst du wirklich löschen', buttonCancel: 'Abbrechen', buttonText: 'Löschen'}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.deleteEvent.emit();
      }
    });
  }
}
