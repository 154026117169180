export class Upload {
  id: string;
  file: File;
  name: string;
  url: string;
  urlThumb?: string;
  progress: number;
  createdOn: Date = new Date();
  description?: string;
  category?: string;
  sort: number;
  comment?: string;
  mark?: string;
  technique?: string;
  size?: string;
  price?: string;

  constructor(file: File, values: Upload) {
    this.file = file;
    this.description = values.description;
    this.category = values.category;
    this.sort = values.sort;
    this.comment = values.comment;
    this.mark = values.mark;
    this.technique = values.technique;
    this.size = values.size;
    this.price = values.price;
  }
}
