import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {GalleryImage} from "../../../../admin-lib/src/lib/models/galleryImage";

@Component({
  selector: 'app-edit-dialog-box',
  templateUrl: 'edit-dialog.component.html',
})

export class EditDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: GalleryImage) {
  }
}
