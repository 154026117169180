import {Component, OnInit} from '@angular/core';
import {GalleryImage} from "../../../../admin-lib/src/lib/models/galleryImage";
import {select, Store} from "@ngrx/store";
import {getAllImages, ImagesState} from "../../../../admin-lib/src/lib/store/images.selectors";
import {loadAllImages} from "../../../../admin-lib/src/lib/store/images.actions";

@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss'],
})

export class ImageListComponent implements OnInit {

  public images: GalleryImage[];

  constructor(
    private store: Store<ImagesState>,
  ) {
  }

  public ngOnInit(): void {
    // get images form store
    this.store.pipe(select(getAllImages)).subscribe(images => this.images = images);
    this.store.dispatch(loadAllImages());
  }
}
