import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {GalleryImage} from "../../../../admin-lib/src/lib/models/galleryImage";
import {Observable} from "rxjs";
import {AbstractControl} from "@angular/forms";
import {FeatureService} from "../../../../admin-lib/src/lib/services/feature.service";
import {AuthenticationService} from "../../../../admin-lib/src/lib/services/authentication.service";
import {ImageService} from "../../../../admin-lib/src/lib/services/image.service";
import firebase from 'firebase/compat/app';
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSort} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {EditDialogComponent} from "./edit-dialog.component";

@Component({
  selector: 'app-image-detail-table',
  templateUrl: './image-detail-table.component.html',
  styleUrls: ['./image-detail-table.component.scss'],
})
export class ImageDetailTableComponent implements OnInit, OnChanges {
  public user: Observable<firebase.User>;

  @Input()
  public imageData: GalleryImage[];

  @Input()
  public isList?: boolean;

  @ViewChild(MatSort)
  public sort: MatSort;

  public dataSource = new MatTableDataSource([]);
  public displayedColumns: string[] = ['description', 'technique', 'size', 'price'];
  public category: AbstractControl;
  public description: AbstractControl;
  public technique: AbstractControl;
  public size: AbstractControl;
  public price: AbstractControl;

  constructor(
    public imageService: ImageService,
    private authService: AuthenticationService,
    private featureService: FeatureService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
  ) {
  }

  public ngOnInit(): void {
    this.user = this.authService.authUser();
    this.user.subscribe(user => {
      if (user) {
        this.featureService.getProfileFromUser(user.uid).subscribe(userProfile => {
          if (userProfile.editImage && this.imageData.length > 0) {
            this.setColumnsWithEditRights();
            this.populateList(this.imageData);
          }
        });
      }
    });
  }

  public ngOnChanges(): void {
    if (this.imageData?.length > 0) {
      this.populateList(this.imageData);
    }
  }

  public editImageDetails(value: GalleryImage) {
    this.dialog.open(EditDialogComponent, {
      data: value
    });
  }

  public deleteEntry(img: GalleryImage): void {
    this.imageService.deleteFileDatabase(img.id).then(() => {
      this.imageService.deleteFileStorage(img.name);
      this.route.url.subscribe(data => {
          const url = data[0].path;
          if (url !== 'imagelist') {
            this.route.parent.url.subscribe(data => {
              this.router.navigate([`gallery/${data[1].path}`]);
            })
          }
        }
      );

    })
      .catch(error => console.log("Error Deleting From Database:", error));
  }

  public trackByFn(index, item) {
    return item.id; // or item.id
  }

  private setColumnsWithEditRights(): void {
    this.displayedColumns = ['urlThumb', 'category', 'description', 'technique', 'size', 'price', 'edit', 'delete'];
  }

  private populateList(data): void {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
  }
}
