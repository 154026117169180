import {Directive, EventEmitter, HostListener, Output} from '@angular/core';

@Directive({
  selector: '[hammertime]'
})
export class HammertimeDirective {

  @Output() singletap = new EventEmitter();
  @Output() doubletap = new EventEmitter();

  @HostListener('tap', ['$event'])
  onTap(e) {

    if (e.tapCount === 1) {
      this.singletap.emit(e)
    }

    if (e.tapCount === 2) {
      this.doubletap.emit(e)
    }
  }
}
