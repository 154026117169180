<div [ngClass]="{'list': isList}">
  <mat-table [dataSource]="dataSource"
             [trackBy]="trackByFn"
             class="mat-elevation-z8"
             matSort>
    <ng-container matColumnDef="urlThumb">
      <mat-header-cell *matHeaderCellDef mat-sort-header>&nbsp; &nbsp; Bild</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <div [ngStyle]="{'background-image': 'url('+row.urlThumb+')'}"
             class="image-container"
             routerLink="image/{{row.id}}">
        </div>
      </mat-cell>
    </ng-container>

    <ng-container
      matColumnDef="category">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Kategorie</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <h5>Kategorie</h5>
        {{row.category}}
      </mat-cell>
    </ng-container>

    <ng-container
      matColumnDef="description">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Beschreibung</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <h5>Beschreibung</h5>
        {{row.description}}
      </mat-cell>
    </ng-container>

    <ng-container
      matColumnDef="technique">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Technik</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <h5>Technik</h5>
        {{ row.technique }}
      </mat-cell>
    </ng-container>

    <ng-container
      matColumnDef="size">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Grösse</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <h5>Grösse</h5>
        {{row.size}}
      </mat-cell>
    </ng-container>

    <ng-container
      matColumnDef="price">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Preis</mat-header-cell>
      <mat-cell *matCellDef="let row">
        <h5>Preis</h5>
        {{row.price}}
      </mat-cell>
    </ng-container>

    <ng-container
      matColumnDef="edit">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <button (click)="editImageDetails(row)" mat-icon-button>
          <mat-icon>edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container
      matColumnDef="delete">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <delete (deleteEvent)="deleteEntry(row)"></delete>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
</div>
<router-outlet></router-outlet>
